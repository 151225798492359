import React, { useState } from "react";
import styled from "styled-components";
// import Recaptcha from "react-recaptcha";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm, SubmitHandler, UseFormRegister, ChangeHandler, Controller, FormState } from "react-hook-form";
import { sendEvent } from "../../includes/analytics";
import { Spinner } from "./spinner";

type Inputs = {
  fullname: string,
  email: string,
  captcha: string,
};

type ErrorDisplayProps = {
  errors: FormState<Inputs>['errors'], //Eg. "6168C59742C6A"
  name: keyof FormState<Inputs>['errors']
}

const ErrorDisplay = ({errors, name}:ErrorDisplayProps)=>{
  if (!errors[name]) return <></>;
  return <div className="error-message">{ errors[name]?.message || 'This field is required'}</div>;
};

type ActiveCampaignFormProps = {
  formId: string, //Eg. "6168C59742C6A"
  cta?: string|JSX.Element
}

// Good reference: https://dev.to/saragibby/better-way-to-embed-active-campaign-forms-into-react-n9n
// Also reference https://crometrics.activehosted.com/f/embed.php?id=1 for form_submit jsonp function approach
const ActiveCampaignForm = ({formId, cta}:ActiveCampaignFormProps)=>{
  const { register, handleSubmit, control, formState: { errors } } = useForm<Inputs>();
  
  const [isLoading, setLoading] = useState(false);
  const [isDone, setDone] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data, event) => {
    if (isLoading) return;
    const body = new FormData(event?.target);
    try{
      setLoading(true);
      const response = await fetch(`https://crometrics.activehosted.com/proc.php`, {
        method: "POST",
        mode: "no-cors",
        body,
      });
      // console.log(`response`, response);
      setLoading(false);
      sendEvent('im_lp_form_submission');
      setDone(true);
    }catch(e){
      console.error('API Failure', e);
      setLoading(false);
      setDone(false);
    }
  };

  if (isDone){
    return <div style={{textAlign: "center"}}>
      Thanks for contacting us!<br/>We will get in touch with you shortly.
    </div>;
  } else {
    return <form method="POST" action="https://crometrics.activehosted.com/proc.php" id={`_form_${formId}_`} noValidate onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" name="u" defaultValue={formId} data-name="u" />
      <input type="hidden" name="f" defaultValue={1} data-name="f" />
      <input type="hidden" name="s" data-name="s" />
      <input type="hidden" name="c" defaultValue={0} data-name="c" />
      <input type="hidden" name="m" defaultValue={0} data-name="m" />
      <input type="hidden" name="act" defaultValue="sub" data-name="act" />
      <input type="hidden" name="v" defaultValue={2} data-name="v" />

      <input 
        type="text" 
        placeholder="Your Name" 
        id="fullname" 
        required 
        data-name="fullname" 
        {...register("fullname", { required: true })}
      />
      <ErrorDisplay errors={errors} name="fullname" />

      <input 
        type="email" 
        placeholder="Email" 
        id="email" 
        data-name="email" 
        required 
        {...register("email", { 
          required: true, 
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address"
          } 
        })}
      />
      <ErrorDisplay errors={errors} name="email" />

      <textarea 
        placeholder="Link(s) to your websites you want to optimize" 
        id="field[39]" 
        name="field[39]" 
        data-name="website"
      ></textarea>

      {/* This is the sitekey I generated myself: */}
      {/* <Recaptcha sitekey="6LeHv88cAAAAAI2UZQrpI9HDNCY7e1MwDYk5ev0V" verifyCallback={verifyCallback}/> */}
      {/* This is the sitekey generated by Active Campaign: */}
      <div className="captcha">
        <Controller
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <ReCAPTCHA onChange={onChange} sitekey="6LcwIw8TAAAAACP1ysM08EhCgzd6q5JAOUR1a0Go"/>
          )}
          name="captcha"
          control={control}
        />
      </div>
      <ErrorDisplay errors={errors} name="captcha" />
      {/* <div className="g-recaptcha" data-sitekey="6LcwIw8TAAAAACP1ysM08EhCgzd6q5JAOUR1a0Go"></div> */}
      <button className="cta" type="submit" disabled={isLoading}>{ isLoading ? <Spinner /> : (cta ? cta : <>Get <b>10 Free</b> experimentation ideas</>) }</button>
    </form>;
  }
};

export default ActiveCampaignForm;