import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { media } from '../../includes/breakpoints';
import Section from '../layout/section';
// import { RobotBody, RobotHands } from './svg/form-robot';
import { IStaticImageProps } from 'gatsby-plugin-image/dist/src/components/static-image.server';

const height = 140;
const width = 220;
const desktopGap = 20;
const Clients = styled(Section)`
  background: #D2E7FD;
  
  .container{
    text-align: center;
    padding: 36px 0;
    
    ${media.large`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 80px;
      align-items: center;
      padding: 0 36px;
      text-align: left;
      min-height: 460px;
    `}
  }
  
  .client-list-wrapper{
    overflow: hidden;
    display: flex;
    justify-content: center;
    height: 300px;
    position: relative;
    ${media.large`
      height: 100%;
      width: ${width*2 + desktopGap*3}px;
    `}
  }

  .client-list{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: inline-grid;
    list-style: none;
    padding: 0;
    overflow: hidden;
    margin: 0;
    grid-template-areas: 
    "space1 one one two two three three space2"
    "four four five five six six seven seven";
    grid-template-rows: 1fr 1fr;
    /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
    grid-template-columns: repeat(8, ${width/2 - 16/2}px);
    grid-gap: 16px;

    li{
      display: block;
      content: '‎';
      grid-column: auto;
      height: ${height}px;
      width: ${width}px;
      background: white;
      border-radius: 8px;
      text-align: center;
    }

    li{
      display: flex;
      align-items: center;
      justify-content: center;

      &:nth-child(1){
        grid-area: one;
      }
      &:nth-child(2){
        grid-area: two;
      }
      &:nth-child(3){
        grid-area: three;
      }
      &:nth-child(4){
        grid-area: four;
      }
      &:nth-child(5){
        grid-area: five;
      }
      &:nth-child(6){
        grid-area: six;
      }
      &:nth-child(7){
        grid-area: seven;
      }
    }
  }

  ${media.large`  
    .client-list{
      left: initial;
      top: 50%;
      transform: translateY(-50%);
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      grid-template-areas: 
        "space1 one"
        "two one"
        "two three"
        "four three"
        "four five"
        "six five"
        "six seven"
        "space2 seven";
    }
  `}

  .copy{
    padding: 20px;
    ${media.mobile`
      padding: 36px;
    `}
    ${media.large`
      margin: 100px 0;
      padding: 0;
    `}

    h2{
      font-weight: 300;
      font-size: 24px;
      line-height: 1.5;
      margin-top: 0;
    }
  }
`;

const clientLogoProps:Partial<IStaticImageProps> = {
  height: 140,
  quality: 90,
  className: "client-logo",
  placeholder: "blurred",
  loading: "lazy",
};

export default ()=><Clients id="clients">
  <div className="client-list-wrapper">
    <ul className="client-list">
      <li key="allbirds" data-key="allbirds"><StaticImage src="../../images/logos/allbirds.png" alt="Allbirds" {...clientLogoProps}/></li>
      <li key="doordash" data-key="doordash"><StaticImage src="../../images/logos/doordash.jpg" alt="Doordash" {...clientLogoProps}/></li>
      <li key="earnest" data-key="earnest"><StaticImage src="../../images/logos/earnest.jpg" alt="Earnest" {...clientLogoProps}/></li>
      <li key="fivetran" data-key="fivetran"><StaticImage src="../../images/logos/fivetran.jpg" alt="Fivetran" {...clientLogoProps}/></li>
      <li key="funding" data-key="funding"><StaticImage src="../../images/logos/funding-circle.jpg" alt="Funding Circle" {...clientLogoProps}/></li>
      <li key="metromile" data-key="metromile"><StaticImage src="../../images/logos/metromile.jpg" alt="Metromile" {...clientLogoProps}/></li>
      <li key="bombas" data-key="bombas"><StaticImage src="../../images/logos/bombas.png" alt="Bombas" {...clientLogoProps}/></li>
    </ul>
  </div>
  <div className="copy">
    <h2>Cro Metrics has run more than <b>20,000</b> experiments for 65+ clients in 10 years.</h2>
    <p>This experience is at the heart of the Experiment Idea Machine. Simply fill in your information and we'll send you up to 10 experiment ideas that you can use to jumpstart your optimization program tomorrow.</p>
  </div>
  {/* <FontAwesomeIcon icon={faCoffee}/> Font Awesome Works! <br /> */}
  {/* <Link to="/page2">Link to page 2</Link> */}
</Clients>;