import { css } from 'styled-components';

//Based on https://gist.github.com/azamatsmith/a56fb3f8cd3657db7a6af6ce1f8ea591
export const breakpoints = {
  tiny: '375px',
  mobile: '425px',
  tablet: '768px',
  large: '920px',
};

export const media = {
  // ns = not small
  tiny: (...args) =>
    css`
      @media screen and (min-width: ${breakpoints.tiny}) {
        ${css(...args)}
      }
    `,
  mobile: (...args) =>
    css`
      @media screen and (min-width: ${breakpoints.mobile}) {
        ${css(...args)}
      }
    `,
  // fancy: (...args) =>
  //   css`
  //     @media screen and (min-width: ${breakpoint.tiny}) and (max-width: ${breakpoint.mobile}) {
  //       ${css(...args)}
  //     }
  //   `,
  tablet: (...args) =>
    css`
      @media screen and (min-width: ${breakpoints.tablet}) {
        ${css(...args)}
      }
    `,
  large: (...args) =>
    css`
      @media screen and (min-width: ${breakpoints.large}) {
        ${css(...args)}
      }
    `,
};