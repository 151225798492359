import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Section from '../layout/section';
// import { StaticImage } from 'gatsby-plugin-image';
// import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"
// import { DiscoverRobot } from '../components/svg/discover-robot';
import DiscoverRobot from "../../images/discover-robot.inline.svg";
import { media } from '../../includes/breakpoints';

const Discover = styled(Section)`
  background: #2F2B2F;
  color: white;
  font-size: 18px;

  blockquote{
    margin: 0;
    font-size: 1em;
    line-height: 1.25;
    margin-bottom: 35px;
    font-family: 'Merriweather', serif;
    font-weight: 300;

    ::before{
      content:'❝';
    }
    ::after{
      content:'❞';
    }
  }

  h3{
    font-family: 'Merriweather', serif;
    font-size: 1em;
    line-height: 1.25;
  }

  .robot{
    width: 175px;
    margin: auto;
    margin-top: -75px;

    svg{
      height: auto;
      width: 100%;
    }
  }

  ${media.large`
    font-size: 24px;
    
    .container{
      display: flex;
      align-items: center;
    }

    .robot{
      flex-shrink: 0;
      width: 458px;
      margin-top: -140px;
      order: 1;
    }
  `};
`;

export default ()=><Discover id="discover">
  <div className="robot">
    <DiscoverRobot />
  </div>
  <div className="content">
    <blockquote>We’re Ready to experiment, but where do we even start?</blockquote>
    <blockquote>We’ve run test after test — we’re out of ideas.</blockquote>
    <h3>Either of these sound familiar? Generating new testing ideas is challenging, especially when you’re under pressure to deliver results for a fast-growing business.</h3>
  </div>
</Discover>