import React from "react";
import styled from 'styled-components';

export const ButterbarStyle = styled.aside`
  background: #FF590B;
  color: white;
  padding: 1ex 1em;
  text-align: center;
`;

export type ButterBarProps = {
  message: string;
};

export const ButterBar = ({ message }: ButterBarProps)=>{
  return <ButterbarStyle className="butterbar">{message}</ButterbarStyle>;
}
