import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/home/hero';
import Clients from '../components/home/clients';
import Discover from '../components/home/discover';

export default function Index({ data }) {
  return <Layout>
    <Helmet>
      <title>Experiment Idea Machine</title>
    </Helmet>
    <Hero />
    <Clients />
    <Discover />
  </Layout>;
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        name
      }
    }
  }
`;