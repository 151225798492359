import React from "react";
import { BrowserButterBar } from "./modals/browser-butterbar";
import Header from "./layout/header";
import Footer from "./layout/footer";
import "./layout.scss";
export default class Layout extends React.Component<{}, {}>{
  render(){
    return <>
      <BrowserButterBar />
      {/* <Header /> */}
      <main id="content">{this.props.children}</main>
      <Footer />
    </>;
  }
};